import React, { useState, useEffect } from 'react';
import {
  Row, Col, Form, Input, Select, Divider, Skeleton, Button, Checkbox, ColorPicker
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { axiosClient } from '../../apiClient';

const { Option } = Select;

export default function SellerOptions() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const themeConfig = useSelector((state) => state.themeConfig);
  const dataBase = useSelector((state) => state.indexedDB.dataBase);

  async function getSingleData(dBStoreName, idx) {
    const value = await dataBase.get(dBStoreName, idx);
    return value;
  }

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get('/config/default-values', { withCredentials: true })
      .then((response) => {
        setData({
          ...response.data,
          freight_weight_default: String(response.data.freight_weight_default),
          freight_size_default: String(response.data.freight_size_default),
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setData({
      data,
      ...themeConfig
    });
  }, [themeConfig]);

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    const allValues = {
      ...values,
      isDark: themeConfig.isDark,
      ...(values.colorPrimary &&
        (typeof values.colorPrimary === 'string'
          ? { colorPrimary: values.colorPrimary }
          : { colorPrimary: values.colorPrimary.toHexString() })),
      ...(values.colorTextBase &&
        (typeof values.colorTextBase === 'string'
          ? { colorTextBase: values.colorTextBase }
          : { colorTextBase: values.colorTextBase.toHexString() })),
      ...(values.colorBgBase &&
        (typeof values.colorBgBase === 'string'
          ? { colorBgBase: values.colorBgBase }
          : { colorBgBase: values.colorBgBase.toHexString() })),
    };

    dataBase && getSingleData('config', 5).then((data) => {
      const updatedKeys = ["colorBgBase", "colorPrimary", "colorTextBase", "isDark"];

      updatedKeys.forEach((key) => {
        if (allValues.hasOwnProperty(key)) {
          data.configuration[key] = allValues[key];
        }
      });

      dataBase.put('config', data);
    });

    setLoading(true);
    axiosClient
      .post('/config/default-values', allValues, { withCredentials: true })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      });
  };

  function onlyDigits(e, field) {
    const val = parseInt(e.target.value.replace(/\D/g, ''));
    form.setFields([
      {
        name: field,
        value: val ? String(val) : '0',
      },
    ]);
  }

  const handleColorChange = (color, type) => {
    dispatch({ type: 'SET_THEME', [type]: color });
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          initialValues={data}
        >
          <Divider orientation="left">Customização</Divider>
          <Form.Item name="colorPrimary" label="Cor Principal">
            <ColorPicker
              onChangeComplete={(color) => handleColorChange(color.toHexString(), 'colorPrimary')}
            />
          </Form.Item>
          <Form.Item name="colorTextBase" label="Cor do Texto">
            <ColorPicker
              onChangeComplete={(color) => handleColorChange(color.toHexString(), 'colorTextBase')}
            />
          </Form.Item>
          <Form.Item name="colorBgBase" label="Cor de Fundo">
            <ColorPicker
              onChangeComplete={(color) => handleColorChange(color.toHexString(), 'colorBgBase')}
            />
          </Form.Item>
          <Form.Item name="isDark">
            <Button onClick={() => dispatch({ type: 'SET_THEME', isDark: !themeConfig.isDark })}>
              Alternar para {themeConfig.isDark ? 'Modo Claro' : 'Modo Escuro'}
            </Button>
          </Form.Item>
          <Divider orientation="left">Estoque</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item name="hide_out_of_stock" valuePropName="checked">
                <Checkbox>Esconder produtos sem estoque para os clientes</Checkbox>
              </Form.Item>
              <Form.Item name="order_out_of_stock" valuePropName="checked">
                <Checkbox>Fechar pedidos com estoque negativo</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Frete</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="freight_weight_default"
                label="Peso Padrão"
                onChange={(e) => {
                  const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                  form.setFields([
                    {
                      name: 'freight_weight_default',
                      value: isNaN(val) ? '0' : val,
                    },
                  ]);
                }}
              >
                <Input suffix="kg" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="freight_size_default"
                label="Tamanho Padrão"
                rules={[
                  {
                    type: 'string',
                    max: 4,
                  },
                ]}
              >
                <Input onChange={(e) => onlyDigits(e, 'freight_size_default')} suffix="cm" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
