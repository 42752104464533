function initialState() {
  return {
    isDark: false,
    colorPrimary: '#1677ff',
    colorTextBase: '#000',
    colorBgBase: '#fff',
    salved: false,
  };
}

export function themeConfig(state = initialState(), action) {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        ...(action.isDark !== undefined && { isDark: action.isDark }),
        ...(action.colorPrimary !== undefined && { colorPrimary: action.colorPrimary }),
        ...(action.colorTextBase !== undefined && { colorTextBase: action.colorTextBase }),
        ...(action.colorBgBase !== undefined && { colorBgBase: action.colorBgBase }),
        ...(action.salved !== undefined && { salved: action.salved }),
      };

    default:
      return state;
  }
}
